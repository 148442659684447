
import { FacebookApi } from '@/api';
import FacebookLoginCard from '@/components/facebook/FacebookLoginCard.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { FacebookMessengerPlatformData, PublicBot, SupportedPlatform } from 'ignite360-core';
import _merge from 'lodash.merge';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'public-social-login-facebook',
  components: { FacebookLoginCard },
})
export default class PublicSocialLoginFacebook extends Vue {
  @Prop({ required: true, type: Object })
  readonly bot!: PublicBot;

  isPublishingTestPost = false;

  get canPublishTestPost(): boolean {
    return !!(
      this.bot.platformData[SupportedPlatform.FacebookMessenger].pageAccessToken &&
      this.bot.platformData[SupportedPlatform.FacebookMessenger].pageId
    );
  }

  onConnect(data: Partial<FacebookMessengerPlatformData>) {
    const newBot: PublicBot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.FacebookMessenger]: data,
      },
    });
    this.$emit('update:bot', newBot);
  }

  onDisconnect() {
    const newBot: PublicBot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.FacebookMessenger]: {
          pageId: '',
          pageName: '',
          pageAccessToken: '',
        },
      },
    });
    this.$emit('update:bot', newBot);
  }

  async publishTestPost() {
    const facebookData = this.bot.platformData[SupportedPlatform.FacebookMessenger];
    if (!facebookData?.pageAccessToken || !facebookData?.pageId) {
      return;
    }

    this.isPublishingTestPost = true;
    try {
      await FacebookApi.publishTestPost(this.bot.id, {
        pageAccessToken: facebookData.pageAccessToken,
        pageId: facebookData.pageId,
      });
      this.$notify.success('Successfully published test post');
    } catch (e) {
      this.$notify.error({
        title: 'Publishing a test post failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isPublishingTestPost = false;
    }
  }
}
